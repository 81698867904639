<template>
  <div>
    <gl-title backIcon title="详情"></gl-title>
    <gl-card v-loading="loading">
      <el-form :model="formData" ref="form" label-width="200px" size="mini">
        <el-form-item prop="id" label="订单状态">
          <div> {{orderStatus[formData.orderStatus]}} </div>
        </el-form-item>
        <el-form-item prop="buyerName" label="下单人昵称">
          <div> {{formData.buyerName}} </div>
        </el-form-item>
        <el-form-item prop="receivePhone" label="联系电话">
          <div> {{formData.receivePhone}} </div>
        </el-form-item>
        <el-form-item prop="payType" label="支付方式">
          <div> {{payTypeStatus[formData.payType]}} </div>
        </el-form-item>
        <el-form-item prop="itemName" label="商品标题">
          <div> {{formData.itemName}} </div>
        </el-form-item>
        <el-form-item prop="skuName" label="商品规格">
          <div> {{formData.skuName}} </div>
        </el-form-item>
        <el-form-item prop="itemPrice" label="商品单价">
          <div> {{formData.itemPrice}} </div>
        </el-form-item>
        <el-form-item prop="itemNum" label="商品数量">
          <div> {{formData.itemNum}} </div>
        </el-form-item>
        <el-form-item prop="totalDiscount" label="优惠金额">
          <div> {{formData.totalDiscount}} </div>
        </el-form-item>
        <el-form-item prop="totalPrice" label="总金额(元)">
          <div> {{formData.totalPrice}} </div>
        </el-form-item>
        <el-form-item prop="payPrice" label="实付金额">
          <div> {{formData.payPrice}} </div>
        </el-form-item>
        <el-form-item prop="freight" label="运费">
          <div> {{formData.freight}} </div>
        </el-form-item>
        <el-form-item prop="orderId" label="订单编号">
          <div> {{formData.orderId}} </div>
        </el-form-item>
        <el-form-item prop="createTime" label="订单时间">
          <div> {{formData.createTime}} </div>
        </el-form-item>
        <el-form-item prop="payTime" label="付款时间">
          <div> {{formData.payTime}} </div>
        </el-form-item>
        <el-form-item prop="finishTime" label="确认时间">
          <div> {{formData.finishTime}} </div>
        </el-form-item>
        <el-form-item prop="isSettlement" label="是否结算">
          <div> {{formData.isSettlement == 1 ? '已结算' : '未结算'}} </div>
        </el-form-item>
        <el-form-item prop="settlementTime" label="订单结算时间">
          <div> {{formData.settlementTime}} </div>
        </el-form-item>
        <el-form-item prop="cityName" label="大喜昵称">
          <div> {{formData.cityName}} </div>
        </el-form-item>
        <el-form-item prop="regionName" label="小喜昵称">
          <div> {{formData.regionName}} </div>
        </el-form-item>
        <el-form-item prop="superiorName" label="喜粉昵称">
          <div> {{formData.superiorName}} </div>
        </el-form-item>
        <el-form-item prop="factoryName" label="厂家昵称">
          <div> {{formData.factoryName}} </div>
        </el-form-item>
        <el-form-item prop="designerName" label="设计师昵称">
          <div> {{formData.designerName}} </div>
        </el-form-item>
        <el-form-item prop="authorityTotalMoney" label="官方结算总金额（元）">
          <div> {{formData.authorityTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="cityTotalMoney" label="大喜结算总金额（元）">
          <div> {{formData.cityTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="regionTotalMoney" label="小喜结算总金额（元）">
          <div> {{formData.regionTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="superiorTotalMoney" label="喜粉结算总金额（元）">
          <div> {{formData.superiorTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="taxTotalMoney" label="税费总金额（元）">
          <div> {{formData.taxTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="designerTotalMoney" label="设计师结算总金额（元）">
          <div> {{formData.designerTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="freightTotalMoney" label="运费总金额（元）">
          <div> {{formData.freightTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="factoryTotalMoney" label="厂家结算总金额（元）">
          <div> {{formData.factoryTotalMoney}} </div>
        </el-form-item>
        <el-form-item prop="investTotalMoney" label="投资人结算总金额（元）">
          <div> {{formData.investTotalMoney}} </div>
        </el-form-item>
      </el-form> 
      <gl-flex>
        <el-button @click="handleClose">关闭</el-button>
      </gl-flex>
    </gl-card>
  </div>
</template>

<script>
import {fetchSettle} from '@/api/finance'
export default {
  data(){
    return{
      formData: {},
      loading: false,
      orderStatus: {
        '1': '待付款',
        '2': '待发货',
        '3': '待收货',
        '4': '待评价',
        '5': '已完成',
        '6': '已取消',
        '7': '已关闭',
      },
      payTypeStatus: {
        '1': '微信',
        '2': '支付宝',
        '3': '余额',
        '4': '线下支付',
      },
      itemId: '',
    }
  },
  mounted(){
    this.itemId = this.$route.query.itemId
    this.getDetail()
  },
  methods: {
    async getDetail(){
      this.loading = true
      try{
        const res = await fetchSettle(this.itemId)
        this.loading = false
        this.formData = res.data
      }catch(e){
        this.loading = false
      }
    },
    handleClose(){
      // this.$emit('update:visible', false)
      this.$router.back()
    },
  },
}
</script>

<style>

</style>